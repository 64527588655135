import React, { useRef, useEffect, useState, useLayoutEffect } from "react"
import { Box } from "theme-ui"
import { useSpring, animated as a } from "react-spring"
import { Section } from "elements-ui"

import images from "../assets/images/portraits"

const AnimatedBox = a(Box)

const shuffle = (array) => {
  let m = array.length,
    temp,
    i

  while (m) {
    i = Math.floor(Math.random() * m--)

    temp = array[m]
    array[m] = array[i]
    array[i] = temp
  }

  return array
}

const renderRaves = (arr) =>
  arr.map((data, index) => (
    <Box
      key={index}
      sx={{
        mb: [40, 85],
        bg: "rgba(255,255,255,0.75)",
        p: 30,
        boxShadow: "1px 1px 8px 1px rgba(0, 0, 0, 0.07)",
        h4: {
          m: 0,
        },
        "img, .initials": {
          height: 40,
          width: 40,
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          borderRadius: "50%",
          color: "#fff",
          bg: "gray",
          mr: 10,
        },
      }}
    >
      <Box sx={{ fontSize: [15, 18], lineHeight: 1.75, fontStyle: "italic" }}>
        {data.raveText}
      </Box>
      <Box sx={{ mt: 30, display: "flex", flexWrap: "wrap" }}>
        {data.image !== null ? (
          <img src={data.image.asset.url} alt="avatar" />
        ) : (
          <div className="initials">{data.initials}</div>
        )}
        <Box
          sx={{
            flex: 1,
            display: "flex",
            flexDirection: "column",
            p: { color: "primary", fontWeight: "bold", m: 0 },
          }}
        >
          <h4>
            {data.author}
            {data.position && `, ${data.position}`}
          </h4>
          <p>{data.company}</p>
        </Box>
      </Box>
    </Box>
  ))

export default ({ raves, service }) => {
  const [portraits, setPortraits] = useState(images)
  const gridRef = useRef(null)
  const [{ scroll }, set] = useSpring(() => ({ scroll: 0 }))

  const mid = Math.ceil(raves.length / 2)

  const interpLeft = scroll.to((o) => `translateY(-${o / 13}px)`)
  const interpCenter = scroll.to((o) => `translateY(${o / 15}px)`)
  const interpRight = scroll.to((o) => `translateY(-${o / 16}px)`)

  useEffect(() => {
    setPortraits((state) => shuffle(state))
  }, [])

  useLayoutEffect(() => {
    const onScroll = () => {
      if (gridRef !== null) {
        const offset =
          window.pageYOffset + 500 >= gridRef.current.offsetTop
            ? window.pageYOffset + 500 - gridRef.current.offsetTop
            : 0
        set({ scroll: offset })
      }
    }

    window.addEventListener(`scroll`, onScroll)

    return () => window.removeEventListener(`scroll`, onScroll)
  })

  return (
    <Box sx={{ position: "relative" }}>
      <Box
        sx={{
          position: "absolute",
          top: 0,
          left: 0,
          right: 0,
          bottom: 0,
          zIndex: -1,
          opacity: 1,
          overflow: "hidden",
          ".photo-grid": {
            position: "relative",
            py: [50, 100],
            display: "grid",
            gridGap: [10, 60],
            gridTemplateColumns: ["1fr 1fr", "repeat(3, 1fr)"],
          },
        }}
      >
        <div ref={gridRef} className="photo-grid">
          <Box sx={{ pt: 200 }}>
            <AnimatedBox
              style={{ transform: interpLeft, textAlign: "center" }}
              sx={{ img: { mb: 220, maxWidth: ["100%", "70%"], opacity: 0.2 } }}
            >
              {portraits.slice(0, 4).map((url, index) => (
                <img key={index} src={url} alt="portrait" />
              ))}
            </AnimatedBox>
          </Box>
          <Box sx={{ pt: [200, 350] }}>
            <AnimatedBox
              style={{ transform: interpCenter, textAlign: "center" }}
              sx={{ img: { mb: 220, maxWidth: ["100%", "70%"], opacity: 0.2 } }}
            >
              {portraits.slice(5, 10).map((url, index) => (
                <img key={index} src={url} alt="portrait" />
              ))}
            </AnimatedBox>
          </Box>
          <Box sx={{ pt: 280, display: ["none", "block"] }}>
            <AnimatedBox
              style={{ transform: interpRight, textAlign: "center" }}
              sx={{ img: { mb: 220, maxWidth: ["100%", "70%"], opacity: 0.2 } }}
            >
              {portraits.slice(11, 15).map((url, index) => (
                <img key={index} src={url} alt="portrait" />
              ))}
            </AnimatedBox>
          </Box>
        </div>
      </Box>
      <Section
        sx={{
          p: ["20px", "0 20px 60px"],
          position: "relative",
          h2: { textAlign: "center", fontSize: [24, 36], mb: [60, 150] },
          span: { color: "primary" },
        }}
      >
        <Box sx={{ zIndex: 1 }}>
          <h2>
            Raves About <span> {service}</span>
          </h2>
          <Box
            ref={gridRef}
            sx={{
              display: "grid",
              gridGap: 60,
              gridTemplateColumns: ["1fr", "1fr 1fr"],
            }}
          >
            <div>{renderRaves(raves.slice(0, mid))}</div>
            <div>{renderRaves(raves.slice(mid))}</div>
          </Box>
        </Box>
      </Section>
    </Box>
  )
}
