import man1 from "./man-35.jpg"
import man2 from "./man-40.jpg"
import man3 from "./man-avg.jpg"
import man4 from "./man-blond.jpg"
import man5 from "./man-r.jpg"
import man6 from "./man-l.jpg"
import man7 from "./man-me.jpg"
import man8 from "./man-me2.jpg"
import man9 from "./man-a.jpg"
import man10 from "./man-f.jpg"
import man11 from "./man-g.jpg"
import man12 from "./man-o.jpg"
import man13 from "./man-ol.jpg"
import man14 from "./man-rr.jpg"
import man15 from "./man-s.jpg"
import man16 from "./man-y.jpg"
import woman1 from "./woman-l.jpg"
import woman2 from "./woman-30.jpg"
import woman3 from "./woman-b.jpg"
import woman4 from "./woman-30-l.jpg"
import woman5 from "./woman-a.jpg"
import woman6 from "./child-b.jpg"
import woman7 from "./woman-as.jpg"
import woman8 from "./woman-bb.jpg"
import woman9 from "./woman-bl.jpg"
import woman10 from "./woman-f.jpg"
import woman11 from "./woman-fr.jpg"
import woman12 from "./woman-he.jpg"
import woman13 from "./woman-me.jpg"
import woman14 from "./woman-ov.jpg"

export default [
  man1,
  man2,
  man3,
  man4,
  man5,
  man6,
  man7,
  man8,
  man9,
  man10,
  man11,
  man12,
  man13,
  man14,
  man15,
  man16,
  woman1,
  woman2,
  woman3,
  woman4,
  woman5,
  woman6,
  woman7,
  woman8,
  woman9,
  woman10,
  woman11,
  woman12,
  woman13,
  woman14,
]
