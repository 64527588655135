import React from "react"
import { Box } from "theme-ui"

export default ({ children }) => (
  <Box
    sx={{
      maxWidth: "100%",
      p: ["0 20px 15px", "20px 20px 0", "20px 6vw 0"],
      bg: "bg_header",
      h1: {
        color: "#fff",
        fontSize: [28, 45, 82],
        mb: [0, -13, -28],
        fontWeight: 700,
        opacity: 0,
        animation: "fadeIn ease 0.6s forwards .2s",
      },
    }}
  >
    <Box
      sx={{
        display: "flex",
        alignItems: "center",
        justifyContent: "space-between",
      }}
    >
      <h1>{children}</h1>
    </Box>
  </Box>
)
