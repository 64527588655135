import React from "react"
import { Box, Flex } from "theme-ui"

export default ({ certs = [] }) => (
  <Box
    as="section"
    sx={{
      bg: "#f5f5f5",
      p: "30px 0 60px",
      h2: {
        textAlign: "center",
        mb: 15,
        fontSize: [24, 36],
      },
    }}
  >
    <Box sx={{ mx: "auto", maxWidth: "1300px" }}>
      <h2>Certifications</h2>
      <Flex
        sx={{
          display: "flex",
          flexWrap: "wrap",
          justifyContent: "center",
          alignItems: "center",
          a: {
            display: "block",
            fontFamily: "heading",
            color: "primary",
            m: 30,
            px: 15,
            maxWidth: [300, 200],
            textAlign: "center",
          },
          img: {
            maxHeight: 120,
            height: "100%",
            objectPosition: "center",
            objectFit: "contain",
            maxWidth: [130, 180],
            mixBlendMode: "multiply",
          },
        }}
      >
        {certs.map(({ name, url, image }, index) => (
          <a key={index} href={url} target="_blank" rel="noopener noreferrer">
            <img src={image.asset.url} alt={name} />
            <div>{name}</div>
          </a>
        ))}
      </Flex>
    </Box>
  </Box>
)
