import React from "react"
import { Box } from "theme-ui"
import { Section } from "elements-ui"
import { SEO } from "@maker-ui/seo"

import PortableText from "../components/PortableText"
import ServiceHeader from "../components/ServiceHeader"
import Certifications from "../components/Certifications"
import Raves from "../components/Raves"

const listItems = [
  {
    bold:
      "Research, design, and plan the group process and select the necessary tools for the job",
    text:
      "Whether it is an open discussion or a highly structured agenda, the group’s objectives are front of mind, ensuring the optimal group processes are identified.",
  },
  {
    bold: "Resolve key issues",
    text:
      "When we align and find one common voice, it creates cohesion. Resolve any conflicts prior to meeting to ensure greater creativity, openness and productivity.",
  },
  {
    bold:
      "Set the stage so all participants are on the same page, creating rules of engagement and keeping energy high",
    text:
      "It’s the facilitator’s responsibility to keep everyone involved and active in the process, while maintaining an environment safe for healthy discourse.",
  },
  {
    bold: "Focus on the outcome",
    text:
      "Bringing people together is essential, and it comes with a cost in terms of time and money. Whether it’s planning a straightforward board meeting or a complicated off-site retreat, our job is to keep both eyes on the ball so value is multifaceted: establishing agreements, finding solutions, building teams.",
  },
  {
    bold: "Create next steps",
    text:
      "Action planning for continuity is essential. Being committed to each group’s success, we are available at all times for future support.",
  },
]

export default ({ data }) => {
  const {
    _rawBody,
    name,
    subtitle,
    raves,
    seo,
    certifications,
  } = data.allSanityService.nodes[0]

  return (
    <>
      <SEO title={seo.title} description={seo.description} />
      <ServiceHeader>{name}</ServiceHeader>
      <Section sx={{ variant: "pageBody" }}>
        <h2>{subtitle}</h2>
        <PortableText blocks={_rawBody} />
        <Box sx={{ fontSize: [25, 32], mt: [50, 100] }}>
          Five things that are key to great facilitation:
        </Box>
        <Box sx={{ mt: 60 }}>
          {listItems.map(({ bold, text }, index) => (
            <Box
              key={index}
              sx={{
                mb: 40,
                display: "flex",
                flexDirection: ["column", "initial"],
                border: "1px solid gainsboro",
                ".number": {
                  color: "primary",
                  fontWeight: "bold",
                  width: ["100%", 100],
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  borderRight: ["none", "1px solid gainsboro"],
                  borderBottom: ["1px solid gainsboro", "none"],
                  bg: "#f7f7f7",
                  fontSize: 50,
                  fontFamily: "heading",
                },
                ".text": {
                  p: "10px 25px",
                  flex: 1,
                  h4: { fontSize: [20, 25] },
                },
              }}
            >
              <div className="number">{index + 1}</div>
              <div className="text">
                <h4>{bold}</h4>
                <p>{text}</p>
              </div>
            </Box>
          ))}
        </Box>
      </Section>
      <Raves raves={raves} service="Facilitation" />
      <Certifications certs={certifications} />
    </>
  )
}

export const query = graphql`
  query {
    allSanityService(filter: { slug: { eq: "facilitation" } }) {
      nodes {
        ...ServiceFragment
      }
    }
  }
`
